@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi";
  src: url("./font/Satoshi-Variable.ttf") format("truetype");
}

body {
  background-color: #f8f8f8;
  font-family: "Satoshi", sans-serif;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-slide {
  animation: slide 35s linear infinite;
}
